define(['jquery', '$location'], function($, $location) {
  
  var $takeoverWidget = $('[data-component="takeoverWidget"]'),
    state = 'closed',
    speed = 250;

  $takeoverWidget.each(function() {

    var $takeoverSpecific = $(this),
      $takeoverObj = $(this).find('.takeover'),
      $takeoverBtn = $(this).find('.takeover-btn');

    var teaser = $takeoverObj.attr('src'),
      fullAd = $takeoverObj.attr('path');

    if ($takeoverSpecific.attr('id') === 'open') {
      $takeoverObj.attr('src', $takeoverObj.attr('path'));
      $takeoverSpecific.css({
        height: 415
      });
    }

    $takeoverBtn.on('click', function() {
      var $takeover = $(this).parent();

      state = $takeover.attr('id');

      if (state === 'open') {
        $takeover.attr('id', 'closed');
        $takeover.stop().animate({
          height: 90
        }, {
          duration: speed,
          queue: false,
          complete: function() {
            $takeoverObj.attr('src', teaser);
          }
        });
        return;
      }

      $takeover.attr('id', 'open');
      $takeoverObj.attr('src', fullAd);
      $takeover.stop().animate({
        height: 415
      }, {
        duration: speed
      });
    });

    $takeoverObj.on('click', function() {
      $location.href = $takeoverSpecific.data('href');
    });
  });
});
